<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Danh sách hành động</h6>
        </template>
        <b-row>
          <b-col>
            <b-form-group label="Tiêu đề">
              <b-form-input
                  v-model="filter.title"
                  placeholder="Tiêu đề"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Mô tả">
              <b-form-input
                  v-model="filter.description"
                  placeholder="Mô tả"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Trạng thái">
              <b-form-select :options="status" v-model="filter.status"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Thời gian bắt đầu(*)">
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="filter.date_from"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Thời gian kết thúc(*)">
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="filter.date_to"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button class="mr-2" variant="outline-info" @click="search">Tìm kiếm</b-button>
            <a class="mr-2 btn btn-primary" href="#/tools/segment/action/create">Thêm mới</a>
          </b-col>
        </b-row>

        <hr />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(status)="data">
            {{ convertStatus(data.item.status) }}
          </template>

          <template #cell(created_at)="data">
            {{ formatDate(data.item.created_at) }}
          </template>

          <template #cell(approved_at)="data">
            {{ data.item.approved_at ? formatDate(data.item.approved_at) : "" }}
          </template>

          <template #cell(options)="data">
            <b-button-group size="sm">
              <b-button class="btn btn-sm btn-outline-success" v-if="data.item.status === 'CREATED' || data.item.status === 'REJECTED'" @click="approve(data.item.id)">Duyệt</b-button>
              <b-button class="btn btn-sm btn-outline-danger" v-if="data.item.status === 'CREATED'" @click="reject(data.item.id)">Từ chối</b-button>
              <b-button class="btn btn-sm btn-outline-info" v-if="data.item.id" @click="detail(data.item.id)">Chi tiết</b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import router from "@/router";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  components: {},
  data() {
    return {
      items: [],
      item: {
        name: null,
        time_frame: null,
        message: null,
        status: null,
        params: {},
      },
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        title: null,
        description: null,
        status: null,
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
      },
      status: [
        { text: "Tất cả", value: "" },
        { text: "Đang chờ", value: "PENDING" },
        { text: "Đang khởi tạo", value: "CREATING" },
        { text: "Chờ duyệt", value: "CREATED" },
        { text: "Đã duyệt", value: "APPROVED" },
        { text: "Từ chối", value: "REJECTED" },
        { text: "Đang xử lý", value: "PROCESSING" },
        { text: "Đã xử lý", value: "SUCCESS" },
        { text: "Lỗi", value: "FAILED" },
      ],
      fields: [
        { id: "Id" },
        { title: "Tiêu đề" },
        { description: "Mô tả" },
        { segments_id: "Segment" },
        { action: "Hành động" },
        { status: "Trạng thái" },
        { total: "Tổng số" },
        { process: "Tiến trình" },
        { created_by: "Người tạo" },
        { created_at: "Ngày tạo" },
        { approved_by: "Người duyệt" },
        { approved_at: "Ngày duyệt" },
        { options: "Hành động" },
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách hành động", route: "tools/segment/action" },
    ]);
  },
  methods: {
    convert(params) {
      for (let key in params) {
        if (params[key] == "") {
          delete params[key];
        }
      }
      return params;
    },
    search() {
      this.filter.page = 1;
      router.push({ path: "/tools/segment/action", query: this.filter }).catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getList(this.currentPage);
    },
    getList(page = 1) {
      this.items = [];
      let params = { ...this.filter };
      params = this.convert(params);
      params.page = page;
      this.$bus.$emit("show-loading", true);
      CmsRepository.segmentAction(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.items = response.data.data.data;
          this.paginate.total = response.data.data.total;
          this.paginate.totalPage = response.data.data.last_page;
          this.paginate.currentPage = response.data.data.current_page;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    approve(id) {
      let conf = confirm("Bạn có chắc chắn duyệt yêu cầu này không?");

      if (conf === false) {
        return true;
      }

      this.$bus.$emit("show-loading", true);
      let params = { id: id };
      CmsRepository.approveAction(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.getList();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    reject(id) {
      this.$bus.$emit("show-loading", true);
      let params = { id: id };
      CmsRepository.rejectAction(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.getList();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    detail(id) {
      return this.$router.push({ name: "segmentActionDetail", query: { id: id } });
    },
    convertStatus(status) {
      let constStatus = this.status;
      let result = status;
      constStatus.forEach(function(item) {
        if (item.value === status) {
          result = item.text;
        }
      })

      return result;
    }
  },
  created() {
    this.query = this.$route.query;
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },
};
</script>
